import { apiRequest } from "../../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../../Utils/Auth/AuthToken";
import {
  FORGOT_PASSWORD,
  NEW_PASSWORD,
  APPLICATION_LOG,
  LOG_IN,
  APPLICATION_USER,
  LOOKUP_BY_TYPE,
} from "../../../../constants/apiRoutes";
import {
  USER_TENANTS,
  DEFAULT_TENANT,
  TENANT_ID,
} from "../../../../constants/applicationConstants";
import { createLoginLog } from "../Utils/CommonUtil";

/**
 * getUserByToken.
 * @param data
 */
export const getUserByToken = async ({ data }) => {
  const response = await apiCall({
    endpoint: `${NEW_PASSWORD}/ValidateUserByToken`,
    method: "POST",
    body: data,
  });
  return response;
};

/**
 * getPasswordPolicy.
 * @param gatewayId
 */
export const getPasswordPolicy = async ({ gatewayId }) => {
  const response = await apiCall({
    endpoint: `${NEW_PASSWORD}/GetPasswordPolicy?gatewayId=${gatewayId}`,
    method: "GET",
  });
  return response;
};

/**
 * changePassword.
 * @param data
 */
export const changePassword = async ({ data }) => {
  const response = await apiCall({
    endpoint: `${NEW_PASSWORD}/NewPassword`,
    method: "POST",
    body: data,
  });
  return response;
};

/**
 * changePassword.
 * @param data
 */
export const sendResetLink = async ({ data }) => {
  const response = await apiCall({
    endpoint: `${FORGOT_PASSWORD}/ForgotPassword`,
    method: "POST",
    body: data,
  });
  return response;
};

/**
 * getUserIdByEmail.
 * @param data
 */
export const getUserIdByEmail = async ({ data }) => {
  const response = await apiCall({
    endpoint: `${FORGOT_PASSWORD}/GetUserIdByEmailAddress?email=${data?.email}`,
    method: "GET",
  });
  return response;
};

/**
 * postLoginLog.
 * @param data
 */
export const postLoginLog = async ({ data }) => {
  const response = await apiCall({
    endpoint: `${APPLICATION_LOG}`,
    method: "POST",
    body: data,
  });
  return response;
};

/**
 * login.
 */
export const postLogin = async ({ dataItem }) => {
  const encodedData = Object.keys(dataItem)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(dataItem[key])}`
    )
    .join("&");
  const response = await apiCall({
    endpoint: `${LOG_IN}`,
    method: "POST",
    body: encodedData,
    headers: {
      Accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
    },
  });
  createLoginLog({ dataItem, response, encodedData });
  return response;
};

/**
 * setUserTenant.
 * @param data
 */
export const setUserTenant = async ({ data }) => {
  const baseURL = `${APPLICATION_USER}/${data.userId}/Tenants`;
  const responseData = await apiCall({
    endpoint: baseURL,
    method: "GET",
    token: data.access_token,
  });
  localStorage.setItem(USER_TENANTS, JSON.stringify(responseData.Payload));
  let userTenants = responseData?.Payload.filter(
    (item) => item.IsWrite && item.IsDefault
  );
  localStorage.setItem(DEFAULT_TENANT, JSON.stringify(userTenants[0]));
  localStorage.setItem(TENANT_ID, userTenants[0]?.TenantId.toString());
  return;
};

/**
 * Get Lookup By Type.
 * @returns {Promise} - A promise that resolves with the list of lookup or rejects with an error.
 * @param data
 */
export const getLookupByType = async ({
  moduleName,
  isServiceWorker,
  listController,
  type,
}) => {
  const { accessToken } = getTokensFromStorage();

  const response = await apiCall({
    endpoint: `${LOOKUP_BY_TYPE}?type=${type}`,
    method: "GET",
    token: accessToken,
  });
  return response;
};

const apiCall = async ({
  endpoint,
  method = "GET",
  body,
  token = null,
  headers = null,
}) => {
  if (!headers) {
    headers = {
      "content-type": "application/json",
      Accept: "*/*",
      "X-Application-Id": process.env.APP_ID,
      "X-App-Key": process.env.APP_KEY,
      "X-App-Secret": process.env.APP_SECRET,
      "Accept-Language": "en",
    };
  }

  // Conditionally add Authorization header if token is not null
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const request = {
    baseURL: `${process.env.BASE_URL}${endpoint}`,
    method: method,
    headers: headers,
  };

  if (body) {
    if (typeof body === "object") {
      request.body = JSON.stringify(body);
    } else {
      request.body = body;
    }
  }
  try {
    const response = await apiRequest(request);
    return response;
  } catch (error) {
    console.error("API Request Error:", error);
    throw error;
  }
};
